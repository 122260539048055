<template>
  <el-form-item class="iq-select" :label="label" :prop="prop">
    <el-autocomplete
      v-model="inputVal"
      v-uppercase
      :disabled="disabled"
      class="iq-select"
      :placeholder="placeholder"
      :fetch-suggestions="options"
    />
  </el-form-item>
</template>

<script>
export default {
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isFilterable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      require: true,
    },
    label: {
      type: String,
    },
    prop: {
      type: String,
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style lang="sass">
$color-blue-primary: #4CA7D8

.iq-select
  .info-message
    font-size: 11px
    color: $btn-orange!important
  .el-form-item__label
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: normal !important
    font-size: 14px !important
    line-height: 22px
    color: #606266
  .el-form-item__label:before
    content: '' !important
  .el-select-dropdown
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1))
    border: none
    box-shadow: none
  .el-select-dropdown__item.selected
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 22px
    display: flex
    align-items: center
    font-feature-settings: 'tnum' on, 'lnum' on
    color: $color-blue-primary
.el-form-item
  margin-bottom: 12px!important

  .el-form-item__label
    margin-bottom: 5px !important

  .el-icon-arrow-up:before
    content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.75 22.625L12.5 16.375L6.25 22.625' stroke='%23C0C4CC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important
</style>
